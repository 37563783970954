<template>
  <div class="container-fluid">
    <!--page header start -->
    <div class="row">
      <div class="col-md-12">
        <div class="mb-4 clearfix" style="display:flex;justify-content:center;">
          <b-form-select v-model="filtre_categories" class="input-filter">
                  <b-form-select-option :value="null"> Filtrer par type de notification </b-form-select-option>
                  <b-form-select-option
                      v-for="(tn, j) in typesNotifications"
                      :key="j"
                      :value="tn.libelle"
                    >{{ tn.libelle }}</b-form-select-option>
            </b-form-select>
        </div>
        <div class="separator mb-20"></div>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-alert variant="info" class="text-center" show v-if="!notifications.length">
            <i class="fa fa-exclamation-triangle fa-3x"></i>
            <br />
            <span class="h4 d-inline-flex ml-2"> {{ $t('data.logement_pas_de_logement') }} </span>
          </b-alert>
          <div class="notification-container">
            <div class="notification-main">
              <div class="notif-item" v-for="(tj,i) in 7" :key="i">
                <div class="avatar">
                  <img src="@/assets/img/promoteur-maisonier.webp" alt="">
                </div>
                <div class="text-notif-part">
                  <span class="notif-text">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem, dolores in eos reprehenderit.
                  </span>
                  <span class="notif-text-data">
                    12 mai 2022 à 8h24 | Par Claude Anderson
                  </span>
                </div>
                <button>
                  <i class="ik ik-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- <b-row v-else class="layout-wrap" cols="1" cols-sm="2" cols-md="4" cols-lg="5">
            <b-col
              v-for="(notification, i) in 7"
              :key="notification.idNotification || i"
              class="animated flipInX mb-4"
            >
                <div id="container">
                <div id="success-box">
                  <div class="dot"></div>
                  <div class="dot two"></div>
                  <div class="face">
                    <div class="eye"></div>
                    <div class="eye right"></div>
                    <div class="mouth happy"></div>
                  </div>
                  <div class="shadow scale"></div>
                  <div class="message"><h1 class="alert">CREATION!</h1><p>Miguel TAGNE a ajouté une cité</p></div>
                  <button class="button-box"><h1 class="green">consulter</h1></button>
                </div>
            </div>     
            </b-col>
          </b-row> -->
          <paginator
            hr="top"
            :offset="offset"
            :total="notifications.length || 0"
            :limit="perPage"
            :page="currentPage"
            @pageChanged="(page) => {currentPage = page}"
            @limitChanged="(limit) => {perPage = limit}"
          />
            <!--<div id="container">
                <div id="success-box">
                  <div class="dot"></div>
                  <div class="dot two"></div>
                  <div class="face">
                    <div class="eye"></div>
                    <div class="eye right"></div>
                    <div class="mouth happy"></div>
                  </div>
                  <div class="shadow scale"></div>
                  <div class="message"><h1 class="alert">CREATION!</h1><p>Miguel TAGNE a ajouté une nouvelle cité</p></div>
                  <button class="button-box"><h1 class="green">consulter</h1></button>
                </div>
                <div id="error-box">
                  <div class="dot"></div>
                  <div class="dot two"></div>
                  <div class="face2">
                    <div class="eye"></div>
                    <div class="eye right"></div>
                    <div class="mouth sad"></div>
                  </div>
                  <div class="shadow move"></div>
                  <div class="message"><h1 class="alert">Error!</h1><p>oh no, something went wrong.</p></div>
                  <button class="button-box"><h1 class="red">try again</h1></button>
                </div>
            </div>-->
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>

import Logement from "@/components/_patrimoine/Logement.vue";
import DetailsLogement from "@/components/_patrimoine/DetailsLogement.vue";
const php = require("phpjs");
import { mapGetters } from "vuex"
export default {
  name: "logements",
  components: {
    Logement,
    DetailsLogement,
  },
  data: () => ({
    userObject:storage.get('userObject'),
    //début données liées à l'import de plusieurs logements
    showOverlay: false,
    currentPage: 1,
    perPage: 10,
    search:'',
    filtre_categories: null,
    notifications:[
      {
        id: 1,
        type: "creation",
        user:{
          nom :"TAGNE",
          prenom: "Miguel"
        },
        objet:{
          type:"cite",
          idCite:4
        }

      },
      {
        id: 2,
        type: "creation",
        user:{
          nom :"KENNE",
          prenom: "Patrick"
        },
        objet:{
          type:"batiment",
          idBatiment:2
        }

      },
      {
        id: 3,
        type: "licence_expiration",
        formule:{
          idFormule:1,
          nomFormule:"Platinium",
          prix:150000,
          nbrUtilisateur: 1,
          dateExpiration:"15-06-2022"
        },

      },
      {
        id: 3,
        type: "licence_expirtion",
        formule:{
          idFormule:1,
          nomFormule:"Platinium",
          prix:150000,
          nbrUtilisateur: 1,
          dateExpiration:"15-06-2022"
        },

      },
    ],
    trueNotifications:[],
    typesNotifications:[
      {
        libelle: "creation"
      },
      {
        libelle: "licence"
      },
      {
        libelle: "edition"
      },
      {
        libelle: "suppression"
      },
      {
        libelle: "action"
      },
    ]

  }),
  computed: {
     ...mapGetters(['hasAccess']),
     items() {
      return php.array_slice(this.notifications, this.offset, this.perPage);
    },
    offset() {
      return this.currentPage * this.perPage - this.perPage;
    },

  },
  watch: {
    /**
     * Filtre les logements en fonction du type de logement selectionné
     */
    filtre_categories(value) {
      this.notifications = !php.empty(value)
        ? this.trueNotifications.filter(elt => elt.libelle == value)
        : this.trueNotifications;
    },
    search(value) {
      this.logements = !php.empty(value)
        ? this.trueLogements.filter(elt =>
            elt.refLogement.toLowerCase().includes(value.toLowerCase())
          )
        : this.trueLogements;
    }
  },
  beforeMount() {
      setTimeout(() => {
          this.showOverlay =false;
      }, 3000)
  },
  mounted(){
    this.trueNotifications=this.notifications;
  },
  methods: {



  }
};
</script>
<style scoped>

/*$white: #FCFCFC;
$gray: #CBCDD3;
$dark: #777777;
$error: #EF8D9C;
$orange: #FFC39E;
$success: #B0DB7D;
$secondary: #99DBB4;*/


.input-filter{
  height: 45px; 
  width: 400px; 
  border-radius: 8px; 
  box-shadow: none;
  border: 1px solid #dbdbdb;
}
.notification-container{
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}
.notification-main{
  flex-direction: column;
  width: 70%;
  height: 100%;
}
.notification-main .notif-item{
  margin-bottom: .5rem;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.notif-item .avatar{
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: deepskyblue;
}
.notif-item .avatar img{
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.notif-item .text-notif-part{
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: calc(100% - 6rem);
}
.text-notif-part .notif-text{
  font-weight: bold;
  font-size: .95rem;
  color: #191c22;
}
.text-notif-part .notif-text-data{
  margin-top: .5rem;
  font-size: .8rem;
  color: #777777;
}
.notif-item button{
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: none;
}
#container {
 position: relative;
  margin: auto;
  overflow: hidden;
  /*width: 700px;
  height: 250px;*/
  width: 250px;
  height: 250px;
}

h1 {
  font-size: 0.9em;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #FCFCFC;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.green {
  color: #777777;
}

.red {
  color: #4e4e4e;
}

.alert {
  font-weight: 700;
  letter-spacing: 5px;
}

p {
  margin-top: -5px;
  font-size: 1em;
  font-weight: 100;
  color: #4e4e4e;
  letter-spacing: 1px;
}

button, .dot {
  cursor: pointer;
}

#creation {
  background: linear-gradient(to bottom right, #B0DB7D, #99DBB4);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#CBCDD3);
  perspective: 40px;
}

/*#success-box {
  position: relative;
  left: 12%;
  width: 35%;
  height: 100%;
 
  background: linear-gradient(to bottom right, #B0DB7D, #99DBB4);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#CBCDD3);
  perspective: 40px;
}*/

#success-box {
 /*/ position: relative;
  left: 12%;*/
  width: 100%;
  height: 100%;
 
  background: linear-gradient(to bottom right, #B0DB7D, #99DBB4);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#CBCDD3);
  perspective: 40px;
}

#suppression {
  background: linear-gradient(to bottom left, #EF8D9C, #FFC39E);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#CBCDD3, 10%);
}

#error-box {
  position: absolute;
  width: 35%;
  height: 100%;
  right: 12%;
  background: linear-gradient(to bottom left, #EF8D9C, #FFC39E);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#CBCDD3, 10%);
}

.dot {
  width: 8px;
  height: 8px;
  background: #FCFCFC;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 6%;
}
.dot::hover{
  background: darken(#777777, 20%);
}

.two {
  right: 12%;
  opacity: .5;
}

.face {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

.face2 {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position:absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}

.shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: .5;
  background: #777777;
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}
.move {
  animation: move 3s ease-in-out infinite;
}


.message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 47%;
}

.button-box {
  position: absolute;
  background: #FCFCFC;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 73%;
  left: 25%;
  outline: 0;
  border: none;
  box-shadow: 2px 2px 10px rgba(#777777, .5);
  transition: all .5s ease-in-out;
}
.button-box::hover{
  background: darken(#FCFCFC, 5%);
    transform: scale(1.05);
    transition: all .3s ease-in-out;
}

@keyframes bounce {
  50% {
     transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }
  50% {
    left: 60%;
    transform: rotate(168deg);
  }
  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }
  50% {
    left: 60%;
  }
  100% {
    left: 25%;
  }
}

@media only screen and (max-width:700px){
  
  .notification-main{
    width: 100%;
  }
  .notification-main .notif-item{
    margin-bottom: .5rem;
    padding: 5px 10px;
    border-bottom: 1px solid #dcdcdc;
  }
  .notif-item .avatar{
    height: 30px;
    width: 30px;
  }
  
  .notif-item .text-notif-part{
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: calc(100% - 60px);
  }
  
  .text-notif-part .notif-text{
    font-weight: normal;
    font-size: .95rem;
    text-align: justify;
  }
  
  .notif-item button{
    height: 20px;
    width: 20px;
  }
}

</style>